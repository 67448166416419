import React from "react";
import g from "glamorous";
import { css } from "glamor";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import Menu from "../../components/Menu-en";
import Footer from "../../components/Footer-en";
import ContactFormPage from "../../components/ContactFormPage-en";
import { FaPhone } from "react-icons/fa";
import { Container, Row, Col } from "glamorous-grid";
import { Helmet } from "react-helmet";
import { FaHome } from "react-icons/fa";

export default function Template() {
  const Wrapper = g.main({
    maxWidth: `1000px`,
    margin: `0 auto 2.5em`,
  });

  const Title = g.h1({
    textAlign: `center`,
    fontSize: `38px`,
    color: `#3c4d68`,
    maxWidth: `700px`,
    margin: `1.5em auto 1em`,
    fontVariant: `none`,
    fontWeight: `600`,
    letterSpacing: `.01em`,
    lineHeight: `49px`,
    "@media(max-width: 767px)": {
      fontSize: `32px`,
      lineHeight: `32px`,
    },
  });

  const H2 = g.h2({
    fontSize: `25px`,
    fontWeight: `500`,
    lineHeight: `30px`,
    marginTop: `1em`,
    marginBottom: `1em`,
    "@media(max-width: 767px)": {
      fontSize: `20px`,
      lineHeight: `25px`,
    },
  });

  const cssLink = css({
    color: `#3c4d68`,
  });

  const Breadcrumb = g.ul({
    fontSize: `13px`,
    lineHeight: `20px`,
    marginLeft: `0 !important`,
    marginTop: `0`,
    marginBottom: `10px !important`,
    fontWeight: `500`,
    letterSpacing: `.015em`,
  });

  const BreadcrumbItem = g.li({
    display: `inline`,
    "&:first-of-type": {
      "&::before": {
        content: `none`,
      },
    },
    "&::before": {
      content: `/`,
      padding: `0 .5em`,
    },
  });

  const cssBreadcrumbLink = css({
    color: `#3c4d68`,
    borderBottom: `none`,
  });

  return (
    <Layout>
      <g.Div id="page-wrap" margin={`0 auto`} backgroundColor={`#fcfcfc`}>
        <Helmet>
          <title>Contact us | Dr Kron - Plastic surgon in Paris</title>
          <meta
            name="description"
            content="Contact us by phone or by email to ask your questions to the team about the treatments and procuedures offered by Dr Cédric Kron."
          />
          <html lang="en" />
          <link
            rel="alternate"
            hreflang="fr"
            href="https://www.dr-kron.com/en/contactez-nous/"
          />
          <script type="application/ld+json">{` {
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Contact us",
          "item": "https://www.dr-kron.com/en/contact-us/"
          }]
              }
    `}</script>
        </Helmet>
        <Menu current="contact" pathFr="/contactez-nous/" />
        <Wrapper>
          <Container css={{ padding: `0 15px !important` }}>
            <Row>
              <Col>
                <Title>Contact us</Title>
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to="/" {...cssBreadcrumbLink} title="Page d'accueil">
                      <FaHome
                        css={{
                          fontSize: `1.2em`,
                          paddingBottom: `.2em`,
                          transform: `translateY(5px)`,
                        }}
                      />
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>Contact us</BreadcrumbItem>
                </Breadcrumb>
                <p className="intro">
                  For any question about the surgical procedures and treatments
                  performed by Dr. Cédric Kron's or to make an appointement, do
                  not hesitate to contact us...
                </p>
                <H2>You have a question?</H2>
              </Col>
            </Row>
            <Row>
              <Col span={{ xs: 12 / 12 }}>
                <p>
                  Contact the office by phone or using the contact form below.
                  <br />
                  We will respond as soon as possible.
                </p>
                <a href="tel:+33145628500" {...cssLink}>
                  <FaPhone
                    css={{
                      fontSize: `2em`,
                      color: `#3c4d68`,
                    }}
                  />{" "}
                  (+33) 1 45 62 85 00
                </a>
                <ContactFormPage />
              </Col>
            </Row>
            <Row>
              <Col />
            </Row>
            <Row>
              <Col span={{ sm: 12 / 12, lg: 3 / 12 }}>
                <H2>Our address</H2>
                <b>Medical practice of Dr. Kron</b>
                <br />
                57, Avenue de Villiers
                <br />
                75017 Paris
                <br />
                <br />
                <b>Phone</b> : (+33)1 45 62 85 00
                <br /> <br />
                <b>Subway:</b>
                <br />
                Malesherbes (Line 3),
                <br />
                Monceaux (Line 2)
              </Col>
              <Col span={{ sm: 12 / 12, lg: 9 / 12 }}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10494.725360729135!2d2.306251!3d48.883351!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xcba74be6729079a1!2sDr+C%C3%A9dric+Kron%2C+chirurgien+esth%C3%A9tique!5e0!3m2!1sfr!2sus!4v1532644775397"
                  width="100%"
                  height="300px"
                  frameBorder="0"
                  css={{ marginTop: `2.1em` }}
                  title="Office of Dr Cédric Kron on Google Maps"
                />
              </Col>
            </Row>
            <Row>
              <Col span={{ sm: 12 / 12, md: 6 / 12 }}>
                <H2>Opening hours</H2>
                <ul>
                  <li>Monday: 10h - 16h30</li>
                  <li>Tuesday: 10h - 20h</li>
                  <li>Wedsneday : 8h30- 14h</li>
                  <li>Thursday: 9h30 - 18h30</li>
                  <li>Friday: 10h - 18h00</li>
                </ul>

                <p>
                  <b>Operating days</b> : Monday et thursday
                </p>
              </Col>
              <Col span={{ sm: 12 / 12, md: 6 / 12 }}>
                <H2>Prices</H2>
                <ul>
                  <li>
                    1<sup>st</sup> aesthetic consultation: 100 euros
                  </li>
                  <li>
                    1<sup>st</sup> consultation covered by Social Security: 100
                    euros
                  </li>
                  <li>
                    <Link to="/en/consultations/">
                      Diagnosis for face treatments
                    </Link>
                    : Free
                  </li>
                  <li>
                    <Link to="/en/face/face-treatment-diagnosis/">
                      Body Contouring Consultation
                    </Link>
                    : Free
                    <br />
                    <br />
                  </li>
                </ul>
                <Link to="/en/consultations/" {...cssLink}>
                  <strong>
                    > Learn more about the different types of consultations
                  </strong>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col>
                <H2>Surgical clinics</H2>
                <p>
                  Dr. Kron perform plastic, cosmetic and reconstructive surgery
                  in approved clinics or clinics in network with the CPAM. His
                  consultations and medicial treatment are performed at his
                  medical office located in Paris 17th.
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={{ xs: 12 / 12, sm: 6 / 12 }}>
                <b>Nouvelle Clinique d'Esthétique des Champs-Elysées</b>
                <br />
                61 avenue Franklin D. Roosevelt
                <br />
                75008 Paris
                <br />
                Anesthetist: Dr A. Michon
                <br />
                <br />
              </Col>
              <Col span={{ xs: 12 / 12, sm: 6 / 12 }}></Col>
            </Row>
          </Container>
        </Wrapper>

        <Footer />
      </g.Div>
    </Layout>
  );
}
